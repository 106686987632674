import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "IndexRate";
const indexRateService = {
  get() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, "");
    }
  },

  getByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  getByIndexSourceKey(paging) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/indexRateSource/indexRates/`, paging);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, key, data);
    }
  },

  delete(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${key}`, key);
    }
  },

  getIndexRateSources() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, "indexRateSource");
    }
  },

  getIndexRateSourceByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, `${key}/indexRateSource`);
    }
  },

  createIndexRateSource(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/indexRateSource`, data);
    }
  },

  updateIndexRateSource(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, `${key}/indexRateSource`, data);
    }
  },

  deleteIndexRateSource(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(
        `${resource}/${key}/indexRateSource`,
        `${key}/indexRateSource`
      );
    }
  },
};

export default indexRateService;
